import Link from "next/link";
import { ArrowRightIcon } from "@heroicons/react/20/solid";
import CountUp from "react-countup";
import { useInView } from "react-intersection-observer";

export default function HomeCta() {
  const { ref, inView } = useInView({
    triggerOnce: true,
    threshold: 0.1,
  });

  return (
    <div ref={ref} className="bg-neutral-100">
      <div className="mx-auto max-w-7xl py-16 px-4 sm:px-6 lg:px-8">
        <div className="overflow-hidden rounded-md border border-black bg-black lg:grid lg:grid-cols-2 lg:gap-4 shadow-2xl">
          <div className="px-6 pt-10 pb-12 sm:px-16 sm:pt-16 lg:pr-0 lg:py-20 xl:px-20">
            <div className="lg:self-center">
              <h2 className="text-4xl font-semibold text-white sm:text-4xl">
                <span className="block text-2xl font-medium mb-2">
                  Try Rulebricks today
                </span>
                <span className="block max-w-sm">
                  Deploy your first rule in under{" "}
                  {inView && <CountUp start={30} end={5} duration={1} />}{" "}
                  minutes.
                </span>
              </h2>
              <p className="mt-4 text-xl max-w-sm leading-6 text-neutral-400">
                Rulebricks is the simplest way to give your business an edge
                with automated decision-making.
              </p>
              <Link legacyBehavior href={"/auth/signup"}>
                <a className="mt-8 inline-flex items-center rounded-md border border-transparent bg-white px-5 py-3 text-xl font-medium text-black-600 hover:bg-neutral-300">
                  <span>Get started for free</span>
                  <ArrowRightIcon className="h-6 w-6 ml-2" aria-hidden="true" />
                </a>
              </Link>
            </div>
          </div>
          <div className="aspect-w-5 aspect-h-3 -mt-6 md:aspect-w-2 md:aspect-h-1">
            <img
              className="translate-x-6 translate-y-6 shadow-md shadow-white/10 lg:scale-105 lg:origin-center transform rounded-md object-cover object-right-bottom xl:-translate-x-16 lg:translate-y-20 mt-2"
              src="/static/images/cta-table.png"
              alt="App screenshot"
            />
          </div>
        </div>
      </div>
    </div>
  );
}
