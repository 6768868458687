import { useRef, useState, useEffect, useCallback } from "react";
import {
  IconBolt,
  IconCalculator,
  IconCloudDataConnection,
  IconFlask,
  IconPlayerPlay,
  IconSettings,
  IconUserPlus,
} from "@tabler/icons-react";
import Modal from "./Rule/Modal";

const features = [
  {
    name: "Capture every decision",
    description:
      "Row schedules, local hit policies, & group priorities help you efficiently model every decision.",
    icon: IconSettings,
  },
  {
    name: "Real-time speed",
    description:
      "Solves rules in microseconds, returns responses from server-side execution in milliseconds.",
    icon: IconCloudDataConnection,
  },
  {
    name: "No-code orchestration",
    description:
      "Integrate rules with data from other sources, like databases, APIs, and more using a visual editor.",
    icon: IconUserPlus,
  },
  {
    name: "One-click quizzes & calculators",
    description:
      "Turn rules into sharable forms that can collect data from your users and display results.",
    icon: IconCalculator,
  },
  {
    name: "Powerful version control",
    description:
      "Visualize differences, assign reviewers, target older versions of rules, and rollback with ease.",
    icon: IconBolt,
  },
  {
    name: "Built-in continuous testing",
    description:
      "Build, save, & automate rich test suites to ensure your rules handle critical outcomes.",
    icon: IconFlask,
  },
];

export default function HomeBigImage() {
  const imgRef = useRef(null);
  const cardsRef = useRef(null);
  const [imgDivVisible, setImgDivVisible] = useState(false);
  const [cardsDivVisible, setCardsDivVisible] = useState(false);
  const [videoModalOpen, setVideoModalOpen] = useState(false);

  const vid = useCallback((x) => {
    try {
      x.volume = 0.5;
      x.playbackRate = 0.98;
    } catch (error) {}
  });

  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      const entry = entries[0];
      if (!entry.isIntersecting) return;
      setImgDivVisible(entry.isIntersecting);
    });
    observer.observe(imgRef.current);
  }, []);
  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      const entry = entries[0];
      if (!entry.isIntersecting) return;
      setCardsDivVisible(entry.isIntersecting);
    });
    observer.observe(cardsRef.current);
  }, []);

  useEffect(() => {
    if (!cardsDivVisible) return;
    for (let i = 0; i < features.length; i++) {
      document
        .getElementById("feature-card-" + i.toString())
        .classList.remove("opacity-100", "-transky-y-2");
      setTimeout(
        () => {
          document
            .getElementById("feature-card-" + i.toString())
            .classList.add("opacity-100", "-transky-y-2");
        },
        i * 100 + 200,
      );
    }
  }, [cardsDivVisible]);

  return (
    <div className="relative overflow-hidden bg-whiteline">
      <div className="mx-auto max-w-md px-4 text-left sm:max-w-3xl sm:px-6 lg:max-w-7xl lg:px-8">
        <div className="bg-white/30">
          <h2 className="text-xl pb-1 font-medium text-black lg:bg-opacity-20">
            Your business logic, at your fingertips
          </h2>
          <p className="mt-4 text-4xl font-semibold tracking-tight text-neutral-900 sm:text-5xl lg:bg-opacity-20">
            Build with precision and confidence
          </p>
          <div className="inline-flex">
            <p className="mt-4 sm:mt-5 max-w-4xl sm:text-2xl text-xl text-black lg:bg-opacity-20">
              Model critical decisions and publish powerful API endpoints using
              a familiar, no-code interface that's easy for any process owner to
              understand, update, and share.
            </p>
          </div>
        </div>
        <div className="relative border-b overflow-hidden pt-16 -mx-8 sm:-mx-12 sm:visible">
          <div
            className={
              "mx-auto max-w-7xl px-8 lg:px-8 duration-1000 ease-out " +
              (imgDivVisible
                ? "translate-y-0"
                : "translate-y-36 sm:translate-y-60")
            }
            ref={imgRef}
          >
            <div className="relative z-10 sm:visible group">
              <div className="relative">
                <img
                  className={
                    "mb-[-12%] rounded-md shadow-2xl ring-1 ring-sky-900/10 delay-1000 duration-700 "
                  }
                  src="/static/images/hero.png"
                  width={2432}
                  height={1442}
                  alt=""
                />
                <div className="absolute inset-0 bg-gradient-to-b from-transparent via-transparent to-neutral-900/50 opacity-50 rounded-md"></div>
              </div>
              {/**
               * play button to show modal with video
               */}
              <Modal
                open={videoModalOpen}
                close={() => setVideoModalOpen(false)}
                width="[30rem] max-w-7xl w-full sm:mx-8 md:mx-4"
                button={
                  <button
                    type="button"
                    onClick={() => setVideoModalOpen(true)}
                    className="absolute inset-0 w-full h-full flex flex-col items-center justify-center bg-white/10 group-hover:backdrop-blur-sm duration-300 rounded-md"
                  >
                    <div className="flex -mt-6 md:scale-100 md:group-hover:scale-110 scale-75 md:-mt-12 items-center justify-center border-4 border-sky-400 saturate-50 group-hover:border-sky-200 shadow-2xl group-hover:shadow-sky-700 w-24 h-24 bg-sky-500 group-hover:bg-sky-800 duration-200 backdrop-blur-md rounded-md">
                      <IconPlayerPlay className="w-12 h-12 text-white fill-white" />
                    </div>
                  </button>
                }
                content={
                  <video
                    className="video w-full h-full -mt-2"
                    controls
                    ref={vid}
                    autoPlay
                    playsInline
                  >
                    <source
                      src="https://d1zic6dm9txw4h.cloudfront.net/RULEBRICKSHD.mp4"
                      type="video/mp4"
                    />
                  </video>
                }
              />
            </div>
            <div className="relative" aria-hidden="true">
              <div className="absolute -inset-x-20 bottom-0 bg-gradient-to-t from-white pt-[7%]" />
            </div>
          </div>
        </div>
        <div className="mx-auto mt-12 max-w-7xl px-0 sm:mt-10 md:mt-20 lg:px-0 mb-12 sm:mb-20">
          <dl
            ref={cardsRef}
            className="mx-auto grid max-w-2xl grid-cols-1 gap-x-6 gap-y-6 text-base leading-7 text-neutral-600 sm:grid-cols-2 lg:mx-0 lg:max-w-none lg:grid-cols-3 lg:gap-x-8 lg:gap-y-8"
          >
            {features.map((feature, idx) => (
              <div
                key={feature.name}
                id={"feature-card-" + idx}
                className="relative select-none border-t group rounded-none duration-1000 transition-all opacity-0 p-5 h-40 shadow-neutral-100"
              >
                <dt className="inline text-neutral-600 text-lg font-">
                  <feature.icon
                    className="absolute tracking-wide -top-4 lg:-top-8 mt-2 right-6 h-12 w-12 bg-white p-2 duration-300 rounded-sm outline-1 border text-neutral-400"
                    aria-hidden="true"
                    strokeWidth={1}
                  />
                  {feature.name}
                </dt>{" "}
                <dd className="block font-light text-neutral-400 mt-1">
                  {feature.description}
                </dd>
              </div>
            ))}
          </dl>
        </div>
      </div>
    </div>
  );
}
